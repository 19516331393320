import { Box, Typography } from '@mui/material';
import { ProgramRegistration } from '@guider-global/shared-types';
import { Control, FieldErrors } from 'react-hook-form';
import { Asterisk } from 'components/Asterisk';
import { FormTextFieldTyped } from 'components/FormTextFieldTyped';

export interface ProgramManageGuideDescriptionProps {
  programRegistration: ProgramRegistration | undefined;
  pageName: string;
  control: Control;
  errors: FieldErrors;
}

export function ProgramManageGuideDescription({
  programRegistration,
  pageName,
  control,
  errors,
}: ProgramManageGuideDescriptionProps) {
  return (
    <Box sx={{ my: 6 }} key={`program-registration-question-description`}>
      <Typography variant="h6">
        {`2. `}
        {
          programRegistration?.registration_group?.group_description
            .question_title
        }
        <Asterisk />
      </Typography>
      <Typography variant="subtitle2" color="text.secondary">
        {
          programRegistration?.registration_group?.group_description
            .question_description
        }
      </Typography>
      <FormTextFieldTyped
        name="description"
        control={control}
        label={
          programRegistration?.registration_group?.group_description.input_label
        }
        errors={errors}
        rows={2}
        rules={{
          required: true,
          minLength:
            programRegistration?.registration_group?.group_description
              .minimum_length,
          maxLength:
            programRegistration?.registration_group?.group_description
              .maximum_length,
        }}
        requiredErrorMessage={
          programRegistration?.registration_group?.group_description
            .required_validation_error_text
        }
        minLengthErrorMessage={
          programRegistration?.registration_group?.group_description
            .minimum_length_not_met_validation_error
        }
        maxLengthErrorMessage={
          programRegistration?.registration_group?.group_description
            .maximum_length_not_met_validation_error
        }
        data-cy={`pages_${pageName}_description-free-text-field`}
      />
    </Box>
  );
}
