import { Divider, Typography } from '@mui/material';
import { FC } from 'react';

export interface RenderDividerMessageProps {
  label: string;
  show: boolean;
  'data-cy': string;
}

export const RenderDividerMessage: FC<RenderDividerMessageProps> = ({
  label,
  show,
  'data-cy': dataCy,
}) => {
  if (show)
    return (
      <>
        <Divider />
        <Typography
          data-cy={`${dataCy}-or-continue-email-divider`}
          variant="body1"
          component="h3"
          sx={{ alignSelf: 'center' }}
          color="text.disabled"
        >
          {label}
        </Typography>
      </>
    );

  return null;
};
