import { Box, Typography } from '@mui/material';
import { ProgramRegistration } from '@guider-global/shared-types';
import { Control, FieldErrors } from 'react-hook-form';
import { Asterisk } from 'components/Asterisk';
import { FormTextFieldTyped } from 'components/FormTextFieldTyped';

export interface ProgramManageGuideTitleProps {
  programRegistration: ProgramRegistration | undefined;
  pageName: string;
  control: Control;
  errors: FieldErrors;
}

export function ProgramManageGuideTitle({
  programRegistration,
  pageName,
  control,
  errors,
}: ProgramManageGuideTitleProps) {
  return (
    <Box sx={{ mt: 0 }} key={`program-registration-question-title`}>
      <Typography variant="h6">
        {`1. `}
        {programRegistration?.registration_group?.group_title.question_title}
        <Asterisk />
      </Typography>
      <Typography variant="subtitle2" color="text.secondary">
        {
          programRegistration?.registration_group?.group_title
            .question_description
        }
      </Typography>
      <FormTextFieldTyped
        name="title"
        control={control}
        label={programRegistration?.registration_group?.group_title.input_label}
        errors={errors}
        rules={{
          required: true,
          minLength:
            programRegistration?.registration_group?.group_title.minimum_length,
          maxLength:
            programRegistration?.registration_group?.group_title.maximum_length,
        }}
        requiredErrorMessage={
          programRegistration?.registration_group?.group_title
            .required_validation_error_text
        }
        minLengthErrorMessage={
          programRegistration?.registration_group?.group_title
            .minimum_length_not_met_validation_error
        }
        maxLengthErrorMessage={
          programRegistration?.registration_group?.group_title
            .maximum_length_not_met_validation_error
        }
        data-cy={`pages_${pageName}_title-free-text-field`}
      />
    </Box>
  );
}
