// external
import { FC, useMemo } from 'react';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// internal
import {
  buildSanityImageUrl,
  useSanityBaseLanguage,
} from '@guider-global/sanity-hooks';

// components
import { DropdownMenu } from 'components';

// hooks
import {
  useMemberships,
  useMixpanelEvents,
  useMobileMediaQuery,
  useProfiles,
  useRelationships,
} from 'hooks';

// types
import { IMembership, IProfile, Program } from '@guider-global/shared-types';

export interface ProgramListCardGroupProps {
  program: Program;
}
interface IProgramAction {
  label: string;
  action: () => void;
}

const ProgramListCardGroup: FC<ProgramListCardGroupProps> = ({ program }) => {
  // Hooks
  const { memberships } = useMemberships({ waitForAuthentication: true });
  const { relationships } = useRelationships({ waitForAuthentication: true });
  const navigate = useNavigate();
  const { trackClick, trackScreenTimeAndClick } = useMixpanelEvents();

  const { getProfiles } = useProfiles({ getSilently: false });
  const [profile] = getProfiles();

  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  const programType = program?.program_details?.program_type;
  const programVariation = programType?.program_type_text?.variations?.group;
  const programActionsGuide = programVariation?.program_actions?.guide;
  const programActionsTrainee = programVariation?.program_actions?.trainee;

  const isMobile = useMobileMediaQuery();

  const programSlug = program.metadata.id.current;
  const programImageSource = program?.program_details?.program_image;
  const programImage = programImageSource
    ? buildSanityImageUrl({
        source: programImageSource,
        width: 600,
      })
    : undefined;

  const activeRelationships = useMemo(
    () => relationships().filter((relationship) => !relationship.isConcluded),
    [relationships],
  );

  const traineeRelationships = useMemo(
    () =>
      activeRelationships.filter((relationship) => {
        if (relationship.programSlug !== programSlug) {
          return false;
        }

        const traineeProfiles =
          relationship.traineeProfiles as Partial<IProfile>[];

        const traineeProfilesIds = traineeProfiles.map(
          (traineeProfile) => traineeProfile?.id,
        );

        return traineeProfilesIds.includes(profile.id);
      }),
    [activeRelationships, profile, programSlug],
  );

  const traineeRelationshipsCount = traineeRelationships.length;

  const isTraineeRelationshipLimitEnabled =
    program?.relationships?.relationship_limits
      ?.enable_trainee_relationship_limit;

  const relationshipsLimit =
    program?.relationships?.relationship_limits?.trainee_relationship_limit ??
    5;

  const canHaveTraineeRelationships =
    !isTraineeRelationshipLimitEnabled ||
    relationshipsLimit > traineeRelationshipsCount;

  const programMemberships: IMembership[] = memberships().filter(
    (membership) => membership.programSlug === programSlug,
  );

  const guideMembership: IMembership | undefined = programMemberships.find(
    (membership) => membership.role === 'guide',
  );
  const traineeMembership: IMembership | undefined = programMemberships.find(
    (membership) => membership.role === 'trainee',
  );

  const guideAction = guideMembership
    ? {
        label: programActionsGuide?.manage_guide_profile_button_label ?? '',
        action: () => {
          trackClick('Programs list - Actions - Manage guide profile');
          navigate(`/programs/${programSlug}/manage/guide`);
        },
      }
    : {
        label: programActionsGuide?.register_as_guide_button_label ?? '',
        action: () => {
          trackClick('Programs list - Actions - Become a guide');
          navigate(`/programs/${programSlug}/join/guide`);
        },
      };

  const traineeAction = traineeMembership
    ? {
        label: programActionsTrainee?.find_another_guide_button_label ?? '',
        action: () => {
          trackClick('Programs list - Actions - Find another guide');
          navigate(`/programs/${programSlug}/join/trainee`);
        },
      }
    : {
        label: programActionsTrainee?.register_as_trainee_button_label ?? '',
        action: () => {
          trackClick('Programs list - Actions - Find a guide');
          navigate(`/programs/${programSlug}/join/trainee`);
        },
      };

  const actions: IProgramAction[] = [guideAction];

  canHaveTraineeRelationships && actions.push(traineeAction);

  return (
    <Grid item xs={12} md={6}>
      <Paper
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: isMobile ? '200px' : '240px',
            color: 'white',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: `url(${programImage})`,
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
          }}
        />
        <Box
          sx={{
            py: isMobile ? 2 : 3,
            px: isMobile ? 2 : 3,
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography
              variant="overline"
              component={'h2'}
              color="text.secondary"
            >
              {programVariation?.variation_name}
            </Typography>
            <Typography variant="h5" component={'h2'}>
              {program.metadata.program_name}
            </Typography>
            <Typography
              variant="body2"
              component={'p'}
              sx={{ mt: 1 }}
              color="text.secondary"
            >
              {program.program_details?.program_description}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              mt: 1.5,
            }}
          >
            <Button
              data-cy="components_Programs_ProgramListCard_navigation-button"
              color="info"
              variant="contained"
              fullWidth
              sx={{ mb: isMobile ? 1.5 : 0 }}
              onClick={() => {
                navigate(`/programs/${programSlug}`);
                trackScreenTimeAndClick('Programs list - View program button');
              }}
            >
              {
                baseLanguage.programs.programs_list.program_card_actions
                  .view_program_details_button_label
              }
            </Button>
            {programMemberships.length !== 0 ? (
              <DropdownMenu
                label={baseLanguage.globals.common.actions_button_label}
                onDropdownButtonClick={() =>
                  trackClick('Programs list - Actions')
                }
                actions={actions}
                containerSx={{ width: '100%', ml: isMobile ? 0 : 2 }}
                variant="outlined"
              />
            ) : undefined}
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};

export default ProgramListCardGroup;
