import { Box, Grid, useTheme } from '@mui/material';
import { PageContent } from 'components/Page';
import { FC } from 'react';
import { RiErrorWarningLine } from 'react-icons/ri';

export interface ProgramsListEmptyProps {
  title: string;
  subtitle: string;
  show?: boolean;
}

const ProgramsListEmpty: FC<ProgramsListEmptyProps> = ({
  title,
  subtitle,
  show,
}) => {
  // Styles
  const theme = useTheme();

  if (!show) return <></>;

  return (
    <Grid key={`programs-list-empty`} item xs={12}>
      <Box
        py={1}
        sx={{
          width: '100%',
          height: '100%',
          maxWidth: '1440px',
        }}
      >
        <PageContent
          title={title}
          subtitle={subtitle}
          icon={RiErrorWarningLine}
          iconColor={theme.palette.warning.main}
          containerStylings={{ height: '100%', py: { xs: 24, md: 10 } }}
        />
      </Box>
    </Grid>
  );
};

export default ProgramsListEmpty;
