import { Collapse, TextField } from '@mui/material';
import { ChangeEvent, FC, useEffect, useState } from 'react';

import { BaseLoadingButton, Stack, StaticAlert } from '@guider-global/ui';

import { useAppSelector } from 'store/hooks';
import { selectRegistrationType } from 'store/slices/appSlice';

const DATA_CY_PAGE = 'register_local_account';

interface RegisterLocalAccountProps {
  label: string;
  enableInfoAlert?: boolean;
  infoMessage?: string;
  error?: boolean;
  errorMessage?: string;
  value?: string;
  loading?: boolean;
  buttonLabel: string;
  disableButton?: boolean;
  onClickButton: () => void;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const RegisterLocalAccount: FC<RegisterLocalAccountProps> = ({
  label,
  enableInfoAlert,
  infoMessage,
  error,
  errorMessage,
  value,
  loading,
  handleChange,
  onClickButton,
  buttonLabel,
  disableButton,
}) => {
  const [hideInput, setHideInput] = useState<boolean>(false);
  const registrationPageType = useAppSelector(selectRegistrationType);

  const showRegisterLocalAccount =
    registrationPageType === 'localAccounts' ||
    registrationPageType === 'localAccountsAndSSO';

  const handleClickButton = () => {
    if (enableInfoAlert) {
      setHideInput(true);
    }
    onClickButton();
  };
  useEffect(() => {
    if (!hideInput) return;
    const reshowButton = setTimeout(() => setHideInput(false), 10000);
    return () => {
      clearTimeout(reshowButton);
    };
  }, [hideInput]);

  if (showRegisterLocalAccount)
    return (
      <div>
        <Collapse in={hideInput}>
          <StaticAlert fontSize="body2" message={infoMessage ?? ''} />
        </Collapse>
        <Collapse in={!hideInput}>
          <Stack
            spacing={2}
            direction="column"
            justifyContent="center"
            alignContent="stretch"
            alignItems="stretch"
          >
            <TextField
              error={error}
              label={label}
              value={value}
              helperText={error ? errorMessage : ''}
              variant="outlined"
              onChange={handleChange}
              data-cy={`${DATA_CY_PAGE}-email-text-field`}
              color="info"
              sx={{ width: '100%' }}
            />
            <BaseLoadingButton
              variant="outlined"
              onClick={handleClickButton}
              data-cy={`${DATA_CY_PAGE}-email-register-Button`}
              loading={loading}
              disabled={disableButton}
            >
              {buttonLabel}
            </BaseLoadingButton>
          </Stack>
        </Collapse>
      </div>
    );

  return null;
};
