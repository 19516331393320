import { Box } from '@mui/material';
import { FC, useCallback } from 'react';

import { SSOloginButton } from 'components/Authentication';
import { buildSanityImageUrl } from '@guider-global/sanity-hooks';

import { SanityImage, SSOConfigurations } from '@guider-global/shared-types';
import { useAppSelector } from 'store/hooks';
import { selectRegistrationType } from 'store/slices/appSlice';

export interface RegisterSSOProps {
  organizationId: string;
  handleRegister: (connection: string, organizationId: string) => void;
  loading: boolean;
  organizationSSOConfigurations: SSOConfigurations;
}

const DATA_CY_PAGE = 'register_SSO';

export const RegisterSSO: FC<RegisterSSOProps> = ({
  handleRegister,
  loading,
  organizationId,
  organizationSSOConfigurations,
}) => {
  const registrationPageType = useAppSelector(selectRegistrationType);

  const handleSSORegister = useCallback(
    (connection: string) => {
      handleRegister(connection, organizationId);
    },
    [handleRegister, organizationId],
  );
  const getSvg = useCallback((image: SanityImage) => {
    const imageSource = buildSanityImageUrl({
      source: image,
      format: 'svg',
      height: 22,
    });

    return <img src={imageSource} height="22px" alt={`SSO Logo`} />;
  }, []);

  const refactoredSSOConfigurations = organizationSSOConfigurations.map(
    (configuration) => {
      return {
        ...configuration,
        handleRegister: () =>
          handleSSORegister(configuration.auth0_connection_id),
      };
    },
  );

  const showRegisterSSO =
    registrationPageType === 'multitenantsSSO' ||
    registrationPageType === 'localAccountsAndSSO';

  if (showRegisterSSO)
    return (
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column nowrap',
          justifyContent: 'center',
          alignContent: 'stretch',
          alignItems: 'stretch',
          gap: 2,
        }}
      >
        {refactoredSSOConfigurations.map((configuration) => (
          <SSOloginButton
            key={`sso-button-${configuration.auth0_connection_id}`}
            onClick={configuration.handleRegister}
            loading={loading}
            data-cy={`${DATA_CY_PAGE}-button`}
            icon={getSvg(configuration.image)}
          >
            {configuration.button_label}
          </SSOloginButton>
        ))}
      </Box>
    );

  return null;
};
